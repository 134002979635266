import React, { useState } from 'react';
import { ButtonGroup, IconButton } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import LegajoService from '../../../services/legajo';
import CustomInput from '../general/CustomInput';
import { sortDataList, filterData } from "../../../utils/function";
import { CODES } from "../../../utils/codesHTTP";
import { simpleAlerts } from '../../../utils/alerts';
import _ from 'lodash';
import Swal from 'sweetalert2';

const columns = [
    { id: 'id_tipoLegajo', label: 'Cód.', width: 50, align: 'center', },
    { id: 'tipoLegajo', label: 'Descripción', width: 200, align: 'left', },
    { id: 'actions', label: <ConstructionIcon key="icon" fontSize='small' />, width: 50, align: 'center', },
];

const BodyTypeLegajo = (props) => {
    const { getTypeLegajo, search, dataTypeLegajo, setDataTypeLegajo, setOpenLoader, setTitleLoader, handleOpen } = props;

    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });

    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };

    let resultSearch = filterData(dataTypeLegajo, search, ['tipoLegajo']);
    const sortedDataWorkers = sortDataList(resultSearch, sortConfigTable);

    const handleOnChange = (info, key, value) => {
        let updData = _.cloneDeep(dataTypeLegajo);
        if (info.id_tipoLegajo) {
            const idx = updData.findIndex(e => +e.id_tipoLegajo === +info.id_tipoLegajo);
            if (idx !== -1) {
                updData[idx][key] = value;
            }
        } else {
            updData[0][key] = value
        }
        return setDataTypeLegajo(updData);
    }

    const saveTypeLegajos = async (info) => {
        const resultSwal = await Swal.fire({
            title: `Seguro de guardar el tipo de legajo`,
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
        });

        if (resultSwal?.isConfirmed) {
            try {
                setOpenLoader(true);
                setTitleLoader('Guardando información ...');
                const result = await LegajoService.saveTypeLegajo(info);
                if (result.status === CODES.CREATE_201) {
                    getTypeLegajo();
                    simpleAlerts(result?.data?.message || 'Tipo de legajo guardado', 'success')
                } else {
                    simpleAlerts(result?.response?.data?.message || 'No se pudo guardar el tipo de legajo', 'error')
                    setOpenLoader(false);
                    setTitleLoader(null);
                }
                return []
            } catch (error) {
                console.log(`Error en saveTypeLegajo: ${error}`);
            }
        }
    }

    return (
        <div className="frame-table">
            <div className="table-responsive body-table">
                <table className="table table-hover table-striped table-bordered">
                    <thead className='sticky-top' style={{ zIndex: 1 }}>
                        <tr>
                            {columns.map((column) => (
                                <th
                                    scope="col"
                                    className='header-table'
                                    key={column.id}
                                    style={{
                                        textAlign: column.align,
                                        width: column.width,
                                        maxWidth: column.width
                                    }} onClick={() => handleSortTable(column.id)}
                                >
                                    {column.label}

                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        {sortedDataWorkers?.map((row, indexRow) => {
                            return (
                                <tr key={'row' + indexRow} style={{ whiteSpace: 'nowrap' }}>
                                    {columns.map((column, indexColumn) => {
                                        const value = row[column.id];
                                        return (
                                            <td key={'col' + indexColumn} align={column.align}>
                                                {column.id === 'actions' ?
                                                    (+row.edit === 1 ?
                                                        <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                                                            <IconButton
                                                                aria-label="upload"
                                                                size="small"
                                                                title="Guardar tipo de legajo"
                                                                onClick={() => saveTypeLegajos(row)}
                                                            >
                                                                <SaveAsIcon fontSize="small" color={'success'} />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                size="small"
                                                                title="Cancelar edicion"
                                                                onClick={getTypeLegajo}
                                                            >
                                                                <CancelIcon fontSize="small" color={'error'} />
                                                            </IconButton>
                                                        </ButtonGroup>
                                                        : <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                                                            <IconButton
                                                                aria-label="upload"
                                                                size="small"
                                                                title="Editar tipo de legajo"
                                                                onClick={() => handleOpen(true, row)}
                                                            >
                                                                <EditIcon fontSize="small" color={'success'} />
                                                            </IconButton>
                                                            {/* <IconButton
                                                                aria-label="delete"
                                                                size="small"
                                                                title="Eliminar tipo de legajo"
                                                                onClick={() => deleteTypeLegajos(row)}
                                                            >
                                                                <DeleteIcon fontSize="small" color={'error'} />
                                                            </IconButton> */}
                                                        </ButtonGroup>
                                                    ) : (+row.edit === 1 && column.id !== 'id_tipoLegajo') ?
                                                        <CustomInput
                                                            placeholder={"Nota para el documento"}
                                                            value={value}
                                                            onChange={(e) => handleOnChange(row, column.id, e.target.value)}
                                                        />
                                                        : value}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {/* custom */}
        </div>
    );
};

export default BodyTypeLegajo