import React from 'react'
import CustomInput from '../../general/CustomInput';
import CustomSelect from '../../general/CustomSelect';

const InformationTypeLegajo = ({ dataType, dataCecos, status, infoTypeLegajo, setInfoTypeLegajo }) => {

    const handleChangeCheck = (check, field) => {
        let value = 1;
        if (check) {
            value = 1;
        } else {
            value = 2;
        }

        setInfoTypeLegajo({ ...infoTypeLegajo, [field]: value })
    };

    return (
        <>
            <div className='row align-items-top'>
                <div className='col-7 mt-1'>
                    <CustomInput
                        placeholder={"Descripción"}
                        title={"Descripción"}
                        type={'text'}
                        value={infoTypeLegajo?.tipoLegajo}
                        onChange={(e) => setInfoTypeLegajo({ ...infoTypeLegajo, tipoLegajo: e.target.value })}
                        required={true}
                    />
                </div>
                <div className='col-5 mt-1'>
                    <CustomInput
                        placeholder={"Valor para el calculo del %"}
                        title={"Peso"}
                        type={'number'}
                        value={infoTypeLegajo?.peso}
                        onChange={(e) => setInfoTypeLegajo({ ...infoTypeLegajo, peso: e.target.value })}
                    />
                </div>
                <div className='col-7 mt-1'>
                    <CustomSelect
                        placeholder={'Seleccionar CECO'}
                        title={"CECO"}
                        options={dataCecos}
                        value={infoTypeLegajo?.centroCosto_id}
                        onChange={(value, event) => setInfoTypeLegajo({ ...infoTypeLegajo, centroCosto_id: value })}
                    />
                </div>
                <div className='col-5 mt-1'>
                    <CustomSelect
                        placeholder={'Seleccionar Tipo'}
                        title={"Tipo"}
                        options={dataType}
                        value={infoTypeLegajo?.tipo_id}
                        onChange={(value, event) => setInfoTypeLegajo({ ...infoTypeLegajo, tipo_id: value })}
                    />
                </div>
                <div className='col-4 mt-1'>
                    <div
                        style={{
                            border: '1px solid #808b96',
                            borderRadius: '15px',
                            marginTop: '15px',
                            paddingLeft: '10px',
                            paddingBottom: '10px',
                        }}
                    >
                        <div
                            style={{
                                position: 'relative', //'absolute',
                                top: '-10px', // Ajusta la posición vertical del título
                                left: '2px', // Ajusta la posición horizontal del título
                                background: 'white', // Fondo blanco para que se vea sobre el borde
                                padding: '0 5px', // Espaciado interno para que no toque el texto
                                display: 'inline-block',
                                border: '1px solid #d5dbdb',
                                borderRadius: '15px',
                            }}
                        >
                            Obligatorio para el calculo:
                        </div>
                        <div className="checkbox-custom">
                            <ElementCheck
                                info={infoTypeLegajo}
                                label={'Obligatorio'}
                                id={'obligatorio'}
                                field={'obligatorio'}
                                handleChangeCheck={handleChangeCheck}
                                valueCheck={infoTypeLegajo?.obligatorio}
                                valueCompare={1}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-4 mt-1'>
                    <div
                        style={{
                            border: '1px solid #808b96',
                            borderRadius: '15px',
                            marginTop: '15px',
                            paddingLeft: '10px',
                            paddingBottom: '10px',
                        }}
                    >
                        <div
                            style={{
                                position: 'relative', //'absolute',
                                top: '-10px', // Ajusta la posición vertical del título
                                left: '2px', // Ajusta la posición horizontal del título
                                background: 'white', // Fondo blanco para que se vea sobre el borde
                                padding: '0 5px', // Espaciado interno para que no toque el texto
                                display: 'inline-block',
                                border: '1px solid #d5dbdb',
                                borderRadius: '15px',
                            }}
                        >
                            Ver en:
                        </div>
                        <div className="checkbox-custom">
                            <ElementCheck
                                info={infoTypeLegajo}
                                label={'Activos'}
                                id={'active'}
                                field={'estadoVistaActivo'}
                                handleChangeCheck={handleChangeCheck}
                                valueCheck={infoTypeLegajo?.estadoVistaActivo}
                                valueCompare={1}
                            />
                            <ElementCheck
                                info={infoTypeLegajo}
                                label={'Cesados'}
                                id={'ceased'}
                                field={'estadoVistaCesado'}
                                handleChangeCheck={handleChangeCheck}
                                valueCheck={infoTypeLegajo?.estadoVistaCesado}
                                valueCompare={1}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-4 mt-1'>
                    <CustomSelect
                        placeholder={'Seleccionar Estado'}
                        title={"Estado"}
                        options={status}
                        value={infoTypeLegajo?.estado_id}
                        onChange={(value, event) => setInfoTypeLegajo({ ...infoTypeLegajo, estado_id: value })}
                    />
                </div>
            </div>
        </>
    )
}

const ElementCheck = (props) => {
    const { info, label, id, field, handleChangeCheck, valueCheck, valueCompare, } = props;
    return (
        <>
            <input type="checkbox" id={`checkbox-rect` + id} name="check"
                checked={valueCheck === valueCompare ? true : false}
                onChange={(e) => handleChangeCheck(e.target.checked, field, info)}
            />
            <label htmlFor={`checkbox-rect` + id} >{label}</label>
        </>
    )
}

export default InformationTypeLegajo