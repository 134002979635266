import React from 'react';
import LegajoService from '../../../../services/legajo';
import CustomModal from '../../../components/general/CustomModal';
import CustomButton from '../../../components/general/CustomButton';
import InformationTypeLegajo from '../../../components/TypeLegajo/AddTypeLegajo/InformationTypeLegajo';
import { simpleAlerts } from '../../../../utils/alerts';
import { CODES } from '../../../../utils/codesHTTP';
import Swal from 'sweetalert2';

const AddTypeLegajo = (props) => {
    const { dataTypeLegajo, dataType, dataCecos, status, infoTypeLegajo, setInfoTypeLegajo, getTypeLegajo,
        open, setOpen, setOpenLoader, setTitleLoader, } = props;

    const cleanInfo = () => {
        setOpen(false);
        setInfoTypeLegajo(null);
    }

    const saveTypeLegajos = async (e) => {
        e.preventDefault();
        const body = {
            ...infoTypeLegajo,
            "estadoVista":
                (infoTypeLegajo?.estadoVistaActivo === 1 && infoTypeLegajo?.estadoVistaCesado === 1) ? -1 :
                    (infoTypeLegajo?.estadoVistaActivo === 1) ? 1 :
                        (infoTypeLegajo?.estadoVistaCesado === 1) ? 2 :
                            -1
        };
        if (infoTypeLegajo.tipo_id === 0 || !infoTypeLegajo.tipo_id) {
            simpleAlerts("Debe seleccionar el tipo de legajo!", "warning");
            return
        }
        if (infoTypeLegajo.centroCosto_id === 0 || !infoTypeLegajo.centroCosto_id) {
            simpleAlerts("Debe seleccionar un ceco!", "warning");
            return
        }
        if (infoTypeLegajo.estado_id === 0 || !infoTypeLegajo.estado_id) {
            simpleAlerts("Debe seleccionar un estado para el legajo!", "warning");
            return
        }
        const resultSwal = await Swal.fire({
            title: `Seguro de guardar el tipo de legajo`,
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
        });

        if (resultSwal?.isConfirmed) {
            try {
                setOpenLoader(true);
                setTitleLoader('Guardando información ...');
                const result = await LegajoService.saveTypeLegajo(body);
                if (result.status === CODES.CREATE_201) {
                    getTypeLegajo();
                    simpleAlerts(result?.data?.message || 'Tipo de legajo guardado', 'success')
                    cleanInfo();
                } else {
                    simpleAlerts(result?.response?.data?.message || 'No se pudo guardar el tipo de legajo', 'error')
                    setOpenLoader(false);
                    setTitleLoader(null);
                }
                return []
            } catch (error) {
                console.log(`Error en saveTypeLegajo: ${error}`);
            }
        }
    }

    return (
        <CustomModal
            size={'md'}
            open={open}
            handleClose={cleanInfo}
            formSubmit={saveTypeLegajos}
            title={'Registro saveTypeLegajos de legajos'}
            subTitle={'Guardar tipos de legajos'}
            bodyModal={
                <InformationTypeLegajo
                    dataTypeLegajo={dataTypeLegajo}
                    dataType={dataType}
                    dataCecos={dataCecos}
                    status={status}
                    infoTypeLegajo={infoTypeLegajo}
                    setInfoTypeLegajo={setInfoTypeLegajo}
                />
            }
            buttonActions={
                <CustomButton
                    key={'btn-save'}
                    variant={'primary'}
                    value={'Guardar'}
                    styleType={'outline'}
                    type={'submit'}
                />
            }
        />
    )
}

export default AddTypeLegajo