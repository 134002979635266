import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class GeneralService {
    async getEmpresas() {
        const ruta = `${API_URL}/general/companies`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async getCliente(id_empresa) {
        const ruta = `${API_URL}/general/client/${id_empresa}`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async getTipo() {
        const ruta = `${API_URL}/general/type`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async getUserType() {
        const ruta = `${API_URL}/general/user-type`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async getCecos() {
        const ruta = `${API_URL}/general/cecos`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async getEstado(queryParameters = {}) {
        const ruta = `${API_URL}/general/status`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            params: queryParameters,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }
}

export default new GeneralService();
