import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material';
import LegajoService from '../../../services/legajo'
import GeneralService from '../../../services/general'
import CustomInput from '../../components/general/CustomInput';
import CustomButton from '../../components/general/CustomButton';
import CustomSelect from '../../components/general/CustomSelect';
import BodyTypeLegajo from '../../components/TypeLegajo/BodyTypeLegajo';
import { CODES } from '../../../utils/codesHTTP'
import DynamicTitle from '../../../utils/DynamicTitle';
import AddTypeLegajo from './AddTypeLegajo/AddTypeLegajo';

const TypeLegajo = ({ userToken, setOpenLoader, setTitleLoader, }) => {
    const [dataTypeLegajo, setDataTypeLegajo] = useState(null);
    const [optionTypeLegajo, setOptionTypeLegajo] = useState(null);
    const [dataType, setDataType] = useState(null);
    const [valueType, setValueType] = useState(0);
    const [dataCecos, setDataCecos] = useState(null);
    const [valueCeco, setValueCeco] = useState('-1');
    const [search, setSearch] = useState("");

    const [status, setStatus] = useState(null);
    const [open, setOpen] = useState(false);
    const [infoTypeLegajo, setInfoTypeLegajo] = useState({});

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        setOpenLoader(true);
        setTitleLoader("Cargando información ...");
        Promise.all([
            getType(),
            getCecos(),
            getEstado(),
        ]).then(() => { setOpenLoader(false); setTitleLoader(null); getTypeLegajo(); });
    }

    const getTypeLegajo = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader("Listando tipos de legajo ...");
            setOptionTypeLegajo([])
            setDataTypeLegajo(null)
            const result = await LegajoService.getTypeLegajo();
            if (result.status === CODES.SUCCESS_200) {
                setDataTypeLegajo(result.data);
                handleOnChange({ value: valueType, ceco: valueCeco, data: result.data });
            }
            return []
        } catch (error) {
            console.log(`Error en getTypeLegajo: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    const getType = async () => {
        try {
            setDataType([])
            const result = await GeneralService.getTipo();
            if (result.status === CODES.SUCCESS_200) {
                setDataType(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getType: ${error}`);
        }
    }

    const getCecos = async () => {
        try {
            setDataCecos([])
            const result = await GeneralService.getCecos();
            if (result.status === CODES.SUCCESS_200) {
                setDataCecos(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getCecos: ${error}`);
        }
    }

    const getEstado = async () => {
        try {
            setStatus([])
            const result = await GeneralService.getEstado();
            if (result.status === CODES.SUCCESS_200) {
                setStatus(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getEstado: ${error}`);
        }
    }

    const handleOnChange = ({ value, ceco, data }) => {
        setOpenLoader(true);
        setTitleLoader("Listando tipos de legajo ...");
        const sourceData = data || dataTypeLegajo;
        const filteredData = sourceData.filter(item => (+item.tipo_id === +value) && (+item.centroCosto_id === +ceco));
        setOptionTypeLegajo(filteredData);
        setOpenLoader(false);
        setTitleLoader(null);
        return setValueType(value);
    }

    const handleOpen = (OpenModal, information) => {
        setOpen(OpenModal);
        setInfoTypeLegajo(information || { "centroCosto_id": valueCeco, "estadoVistaActivo": 1, "obligatorio": 2, "estado_id": "1", "tipo_id": "1" });
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <DynamicTitle title="Tipos de legajos" />
            <AddTypeLegajo
                open={open}
                setOpen={setOpen}
                setOpenLoader={setOpenLoader}
                setTitleLoader={setTitleLoader}
                dataTypeLegajo={dataTypeLegajo}
                dataType={dataType}
                dataCecos={dataCecos}
                status={status}
                getTypeLegajo={getTypeLegajo}
                infoTypeLegajo={infoTypeLegajo}
                setInfoTypeLegajo={setInfoTypeLegajo}
            />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={11} md={5} sx={{ padding: "0 10px" }} mb={2}>
                    <div style={{ display: "flex", alignItems: "end", gap: 10 }}>
                        <div style={{ flex: "1" }}>
                            <CustomInput
                                placeholder={"Buscar por descripción"}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <CustomButton
                            title='Agregar legajo'
                            styleType={'outline'}
                            variant={'success'}
                            value={"agregar"}
                            onClick={() => handleOpen(true, null)}
                        />
                        <CustomButton
                            title='Buscar legajo'
                            variant={'warning'}
                            value={"Buscar"}
                            onClick={() => handleOnChange({ value: valueType, ceco: valueCeco })}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item md={5} xs={8} mt={2}>
                    <CustomSelect
                        placeholder={'Seleccionar Ceco'}
                        title={"Centro de Costo"}
                        options={dataCecos}
                        value={valueCeco}
                        onChange={(value, event) => {
                            setValueCeco(value);
                            handleOnChange({ value: valueType, ceco: value })
                        }}
                    />
                    <CustomSelect
                        placeholder={'Seleccionar Tipo'}
                        title={"Tipo"}
                        options={dataType}
                        value={valueType}
                        onChange={(value, event) => handleOnChange({ value: value, ceco: valueCeco })}
                    />
                    <BodyTypeLegajo
                        setOpenLoader={setOpenLoader}
                        setTitleLoader={setTitleLoader}
                        getTypeLegajo={getTypeLegajo}
                        handleOpen={handleOpen}
                        search={search}
                        dataTypeLegajo={optionTypeLegajo}
                        setDataTypeLegajo={setOptionTypeLegajo}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default TypeLegajo